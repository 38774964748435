import React, { useEffect, useState } from "react";

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";

const REDIRECT_URL = "https://www.arosuite.com/account/Login.aspx";
const REDIRECT_URL_DEMO =
    "https://arosuitebeta.arosuite.com/account/Login.aspx";
const handleLogOut = () => {
    localStorage.clear();
    const currentURL = window.location.href;
    if (currentURL.includes("cuchulainndemo")) {
        window.location.href = REDIRECT_URL_DEMO;
    } else {
        window.location.href = REDIRECT_URL;
    }
};

const InactivityDetector = ({ hours = 2 }) => {
    const time = hours * 60 * 60 * 1000;
    let timeoutId;
    const [openLogOut, setOpenLogOut] = useState(false);

    const onInactive = () => {
        setOpenLogOut(true);
    };
    const redirect = () => {
        handleLogOut();
    };

    const resetTimer = () => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => onInactive(), time);
    };

    useEffect(() => {
        window.addEventListener("mousemove", resetTimer);
        window.addEventListener("keydown", resetTimer);
        window.addEventListener("scroll", resetTimer);

        resetTimer();

        return () => {
            clearTimeout(timeoutId);
            window.removeEventListener("mousemove", resetTimer);
            window.removeEventListener("keydown", resetTimer);
            window.removeEventListener("scroll", resetTimer);
        };
    }, []);

    return (
        <Dialog
            open={openLogOut}
            onClose={redirect}
            aria-labelledby="responsive-dialog-title"
            sx={{ p: 4 }}
        >
            <DialogTitle id="responsive-dialog-title" sx={{ p: 4 }}>
                {"We detected you are away"}
            </DialogTitle>
            <DialogContent sx={{ p: 4 }}>
                <DialogContentText>
                    You have been logged out due to inactivity. Please log in
                    again with Aró Suite to resume your session.
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ pr: 4, pb: 4 }}>
                <Button
                    variant="contained"
                    autoFocus
                    onClick={redirect}
                    sx={{ fontSize: "1.1em" }}
                >
                    Log in
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default InactivityDetector;
