import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setJS } from "../../../actions/globalReportFilters";
import FilterDropdown, {
    FilterChips,
    transformArrToOptions,
} from "../../FiltersDropdown";
import { CheckHideBool } from "../CheckHide";

export const ActiveFiltersLine = ({}) => {
    // Shows a line with the list of actived filters
    const js = useSelector((state) => state.globalReportFilters);
    const dispatch = useDispatch();

    const [activeFilters, setActiveFilters] = useState({
        Countries: [],
        Offers: [],
        Rooms: [],
        Types: [],
        VoucherNames: [],
    });

    useEffect(() => {
        let auxActiveFilters = {
            Countries: (js.filters ?? {}).Countries ?? [],
            Offers: (js.filters ?? {}).Offers ?? [],
            Rooms: (js.filters ?? {}).Rooms ?? [],
            Types: (js.filters ?? {}).Types ?? [],
            VoucherNames: (js.filters ?? {}).VoucherNames ?? [],
        };

        setActiveFilters(auxActiveFilters);
    }, [js]);

    return (
        <FilterChips
            activeFilters={activeFilters}
            setActiveFilters={(activeFilters) => {
                let newJS = {
                    ...js,
                    filters: { ...(js.filters ?? {}), ...activeFilters },
                };

                dispatch(setJS(newJS));
            }}
            filters={{
                Countries: {
                    label: "Country",
                },
                Offers: {
                    label: "Rate plan",
                },
                Rooms: {
                    label: "Room",
                },
                Types: {
                    label: "Type",
                },
                VoucherNames: {
                    label: "Voucher",
                },
            }}
        />
    );
};

const FiltersByType = ({ height = "auto", hide = [] }) => {
    const js = useSelector((state) => state.globalReportFilters);
    const { id: hotelID } = useSelector((state) => state.hotelID);
    const { dataBookings } = useSelector((state) => state);
    const dispatch = useDispatch();

    const [activeFilters, setActiveFilters] = useState({
        Countries: [],
        Offers: [],
        Rooms: [],
        Types: [],
        VoucherNames: [],
    });

    const [countriesOptions, setCountriesOptions] = useState([]);
    const [offersOptions, setOffersOptions] = useState([]);
    const [roomsOptions, setRoomsOptions] = useState([]);
    const [typesOptions, setTypesOptions] = useState([]);
    const [vouchersNamesOptions, setVouchersNamesOptions] = useState([]);

    useEffect(() => {
        if (
            dataBookings.status !== "loaded" &&
            dataBookings.status !== "partiallyLoaded"
        )
            return;

        const countrySet = new Set();
        const offerSet = new Set();
        const roomSet = new Set();
        const typeSet = new Set();
        const voucherNamesSet = new Set();

        dataBookings.data.forEach((e) => {
            if (e.Country && e.Country.trim() !== "") countrySet.add(e.Country);
            if (e.OfferTitle && e.OfferTitle.trim() !== "")
                offerSet.add(e.OfferTitle);
            if (e.RoomName && e.RoomName.trim() !== "") roomSet.add(e.RoomName);
            if (e.Type && e.Type.trim() !== "") typeSet.add(e.Type);
            if (e.VoucherName && e.VoucherName.trim() !== "")
                voucherNamesSet.add(e.VoucherName);
        });

        setCountriesOptions([...countrySet].sort());
        setOffersOptions([...offerSet].sort());
        setRoomsOptions([...roomSet].sort());
        setTypesOptions([...typeSet].sort());
        setVouchersNamesOptions([...voucherNamesSet].sort());
    }, [dataBookings]);

    useEffect(() => {
        let auxActiveFilters = {
            Countries: (js.filters ?? {}).Countries ?? [],
            Offers: (js.filters ?? {}).Offers ?? [],
            Rooms: (js.filters ?? {}).Rooms ?? [],
            Types: (js.filters ?? {}).Types ?? [],
            VoucherNames: (js.filters ?? {}).VoucherNames ?? [],
        };

        setActiveFilters(auxActiveFilters);
    }, [js]);

    return (
        <>
            <FilterDropdown
                title="Filters"
                buttonProps={{ sx: { height: height } }}
                activeFilters={activeFilters}
                setActiveFilters={(activeFilters) => {
                    let newJS = {
                        ...js,
                        filters: { ...(js.filters ?? {}), ...activeFilters },
                    };

                    dispatch(setJS(newJS));
                }}
                filters={{
                    ...(hide.includes("FILTERS_COUNTRIES")
                        ? {}
                        : {
                              Countries: {
                                  label: "Country",
                                  options:
                                      transformArrToOptions(countriesOptions),
                              },
                          }),
                    ...(hide.includes("FILTERS_OFFERS")
                        ? {}
                        : {
                              Offers: {
                                  label: "Rate plans",
                                  options: transformArrToOptions(offersOptions),
                              },
                          }),
                    ...(hide.includes("FILTERS_ROOMS")
                        ? {}
                        : {
                              Rooms: {
                                  label: "Rooms",
                                  options: transformArrToOptions(roomsOptions),
                              },
                          }),
                    ...(hide.includes("FILTERS_TYPES")
                        ? {}
                        : {
                              Types: {
                                  label: "Types",
                                  options: transformArrToOptions(typesOptions),
                              },
                          }),
                    //Types: {
                    //    label: "Types",
                    //    options: transformArrToOptions(typesOptions),
                    //},
                    ...(hide.includes("FILTERS_VOUCHERS")
                        ? {}
                        : CheckHideBool("Vouchers", hotelID) && {
                              VoucherNames: {
                                  label: "Vouchers",
                                  options:
                                      transformArrToOptions(
                                          vouchersNamesOptions
                                      ),
                              },
                          }),
                }}
            />
        </>
    );
};

export default FiltersByType;
