import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Container, Grid, Typography } from "@mui/material";

import { clean } from "../actions/PDFExport";

import Tables2Print from "../components/Tables2Print";
import PDFReport from "../components/PDFReport";
import PDFBuilder from "../components/PDFBuilder";

/* TODO: This will be a screen like MonthlyReportScreen, where:
- /pdfexport -> List of templates of pdf(report arrays with ID associated) to generate and download
- /pdfexport/create -> User can save and transform a report from other screen to a PDF 
- /pdfexport/:reportID -> Go to an existing template associated to that reportID
*/
const PDFExportScreen = () => {
    const dispatch = useDispatch();

    let { reportId } = useParams();

    useEffect(() => {
        return () => {
            dispatch(clean());
        };
    }, []);

    return (
        <>
            {reportId ? (
                <>
                    <PDFReport reportId={reportId} isCollapsible={false} />
                </>
            ) : (
                <>
                    <Container maxWidth={false}>
                        <Grid container>
                            <Grid item sx={{ mt: 2, mb: 2 }} xs={12}>
                                <Typography variant="h4">PDF Export</Typography>
                            </Grid>
                        </Grid>
                        <PDFBuilder />
                    </Container>
                    <br />
                    <br />
                    <br />
                </>
            )}
        </>
    );
};
export default PDFExportScreen;
