import React, { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
    CircularProgress,
    Divider,
    Paper,
    Stack,
    Typography,
} from "@mui/material";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import { SignInButton } from "../components/SignInButton";

import Colours from "../helpers/colours";
import { Authenticate } from "../actions/auth";
import { AROLOGO, AROSUITE_BETA, AROSUITE_MASTER } from "../configuration";

const LoginScreen = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    let isSmall = useMediaQuery(theme.breakpoints.down("sm"));
    const search = useLocation().search;
    const URLtoken = new URLSearchParams(search).get("token");
    const URLhotelID = new URLSearchParams(search).get("hotelid");

    const URLredirect = new URLSearchParams(search).get("redirect"); //  The url when the user was redirected to /login; used to keep track of where to return the user after loggin in
    const URLaaParam = new URLSearchParams(search).get("aa-param"); // redirect url, but encoded for external usage; used for the external loggin in AroSuite so we keep track of where to redirect the user

    const navigate = useNavigate();

    const { loading } = useSelector((state) => state.auth);

    const [url, setUrl] = useState("");

    useEffect(() => {
        if (URLtoken || (URLtoken && URLhotelID)) {
            console.log(URLtoken, URLhotelID);
            dispatch(Authenticate(URLtoken, URLhotelID));
            if (URLaaParam) navigate(atob(URLaaParam)); // Decode the aa-param received and navigate there
        }
    }, [URLtoken, URLhotelID]);

    useEffect(() => {
        console.log({
            environment: process.env.NODE_ENV,
            url: window.location.href,
        });

        let auxUrl = AROSUITE_MASTER;

        if (
            window.location.href.includes("cuchulainndemo.dev.arosuite.com") ||
            process.env.NODE_ENV === "development"
        )
            auxUrl = AROSUITE_BETA;

        auxUrl = auxUrl + `/?aa-param=${URLredirect ?? btoa("/")}`; // Having or not a url to redirect the user, include the aa-param so after AS logging user is automatically redirected back to AA
        if (URLredirect) {
            window.location.href = auxUrl;
        }

        setUrl(auxUrl);
    }, []);

    return (
        <>
            <Paper>
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    sx={{ height: "100vh" }}
                >
                    <Typography
                        color={Colours.textColour}
                        letterSpacing={4}
                        fontSize={32}
                    >
                        WELCOME TO
                    </Typography>
                    <img
                        width={isSmall ? "300em" : "400em"}
                        alt="logo"
                        src={AROLOGO}
                    />
                    <br />
                    <Divider style={{ width: "8em" }} />
                    <br />
                    {!URLtoken && <SignInButton url={url} />}
                    {loading && <CircularProgress />}
                </Stack>
            </Paper>
        </>
    );
};

export default LoginScreen;
