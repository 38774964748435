import React, { useState } from "react";
import { useSelector } from "react-redux";

import {
    Container,
    FormControl,
    MenuItem,
    Select,
    Typography,
} from "@mui/material";

import DataLoader from "../components/DataLoader";
import FiltersReport, { ITEM_HEIGHT } from "../components/FiltersReport";
import ReportBuilder, {
    BREAK_LINE,
    REPORT_CHART_BARS_CATEGORY,
    REPORT_CHART_IN_TABLE,
    GRID_HORIZONTAL,
    REPORT_TABLE_METRICS_SUMMARY,
} from "../components/shared/ReportBuilder/ReportBuilder";

import {
    COMPARISON_SAME_TIME_LAST_YEAR,
    DATATYPE_RESDATE,
    DISPLAY_ELEMENT_HELPER,
    GROUP_DATE_MONTH,
    GROUP_DATE_WEEK,
    GROUP_DATE_YEAR,
    GROUP_OFFERS,
    GROUP_ROOM,
    PRESET_ARRIVAL_CHOOSE_MONTH,
    PRESET_ARRIVAL_CURRENT_MONTH,
    PRESET_ARRIVAL_NEXT_12_MONTHS,
    PRESET_ARRIVAL_NEXT_3_MONTHS,
    PRESET_ARRIVAL_NEXT_MONTH,
    PRESET_CHOOSE_MONTH,
    PRESET_LAST_12_MONTHS,
    PRESET_LAST_30_DAYS,
    PRESET_LAST_3_MONTHS,
    PRESET_LAST_6_MONTHS,
    PRESET_LAST_7_DAYS,
    PRESET_LAST_FULL_MONTH,
    PRESET_MONTH_TO_DATE,
    PRESET_TOMORROW,
    PRESET_YEAR_TO_DATE,
    PRESET_YESTERDAY,
} from "../helpers/filters";

const PacingScreen = () => {
    const [modeWeekMonth, setModeWeekMonth] = useState(GROUP_DATE_MONTH); // GROUP_DATE_MONTH or GROUP_DATE_WEEK
    const js = useSelector((state) => state.globalReportFilters);

    return (
        <>
            <DataLoader Reservations />
            <Container maxWidth={false}>
                <h1>Pacing</h1>
                <Typography variant="subtitle1" color="initial">
                    This report shows the arrivals from a selected booking
                    period.
                </Typography>
                <br />
                <FiltersReport
                    defaultValues={{
                        presetDate: PRESET_MONTH_TO_DATE,
                        presetArrivalDate: PRESET_ARRIVAL_NEXT_12_MONTHS,
                        filters: {
                            Cancellations: "included",
                            Comparison: COMPARISON_SAME_TIME_LAST_YEAR,
                            // Types: ["Special Offer"],
                        },
                        dataType: DATATYPE_RESDATE,
                    }}
                    BookingPresetOptions={[
                        PRESET_MONTH_TO_DATE,
                        PRESET_YEAR_TO_DATE,
                        PRESET_LAST_FULL_MONTH,
                        PRESET_CHOOSE_MONTH,
                        PRESET_YESTERDAY,
                        PRESET_LAST_7_DAYS,
                        PRESET_LAST_30_DAYS,
                        PRESET_LAST_3_MONTHS,
                        PRESET_LAST_6_MONTHS,
                        PRESET_LAST_12_MONTHS,
                    ]}
                    otherProps={{
                        TIME_RANGE: {
                            fromDateProps: {
                                value: new Date(js.fromDay),
                                maxDate: new Date(),
                            },
                            toDateProps: {
                                value: new Date(js.toDay),
                                maxDate: new Date(),
                            },
                        },
                    }}
                    ArrivalPresetOptions={[
                        PRESET_ARRIVAL_NEXT_12_MONTHS,
                        PRESET_ARRIVAL_NEXT_MONTH,
                        PRESET_ARRIVAL_NEXT_3_MONTHS,
                        PRESET_ARRIVAL_CURRENT_MONTH,
                        PRESET_ARRIVAL_CHOOSE_MONTH,
                    ]}
                    hide={[
                        "DATATYPE",
                        "FILTERS_VOUCHERS",
                        "FILTERS_TYPES",
                        "COMPARISON",
                        "CANCELLATIONS_ONLY",
                        "CANCELLATIONS",
                        "TIME_BOOKING_RANGE",
                    ]}
                    PDF
                    rename={{ TIME_PERIOD: "Booking period" }}
                    setProcessed={() => {}}
                    CustomFilters={() => (
                        <>
                            <Typography>Weekly / Monthly</Typography>
                            <FormControl>
                                <Select
                                    labelId="weekMonthFilter"
                                    id="weekMonthFilter"
                                    value={modeWeekMonth}
                                    sx={{
                                        height: ITEM_HEIGHT,
                                        width: "140px",
                                    }}
                                    onChange={(event) => {
                                        let value = event.target.value;
                                        console.log({ value });
                                        setModeWeekMonth(value);
                                    }}
                                    size="small"
                                >
                                    <MenuItem value={GROUP_DATE_WEEK}>
                                        Weekly
                                    </MenuItem>
                                    <MenuItem value={GROUP_DATE_MONTH}>
                                        Monthly
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </>
                    )}
                />
                <ReportBuilder
                    reports={[
                        {
                            type: REPORT_TABLE_METRICS_SUMMARY,
                            groupBy: [],
                            dataType: DATATYPE_RESDATE,
                            fieldsOrder: [
                                "revenueTotal",
                                "revenueRoom",
                                "bookingsRooms",
                                "roomNightsTotal",
                                "ADRTotal",
                                // "ALoSTotal",
                                // "ABWTotal",
                                // "BWTotal",
                                // "bookingsCancelled",
                            ],
                            hideFields: [
                                ...Object.keys(DISPLAY_ELEMENT_HELPER).filter(
                                    (e) =>
                                        ![
                                            "revenueTotal",
                                            "bookingsRooms",
                                            "roomNightsTotal",
                                            "revenueRoom",
                                            "ADRTotal",
                                            // "ALoSTotal",
                                            // "ABWTotal",
                                            // "BWTotal",
                                            // "bookingsCancelled",
                                        ].includes(e)
                                ),
                            ],
                            PDF: "Metrics",
                            // props: { hideEvents: true },
                        },
                        { type: BREAK_LINE, quantity: 1 },
                        {
                            type: REPORT_CHART_IN_TABLE,
                            dataType: DATATYPE_RESDATE,
                            groupBy:
                                modeWeekMonth === GROUP_DATE_WEEK
                                    ? [GROUP_DATE_WEEK]
                                    : [GROUP_DATE_YEAR, GROUP_DATE_MONTH],
                            props: { title: "Arrivals Chart" },
                        },
                        // {
                        //     type: GRID_HORIZONTAL,
                        //     spacing: 2,
                        //     reports: [
                        //         {
                        //             type: REPORT_CHART_BARS_CATEGORY,
                        //             props: { title: "Top selling rooms" },
                        //             groupBy: [GROUP_ROOM],
                        //             metric: "bookingsTotal",
                        //             metrics: {
                        //                 ...DISPLAY_ELEMENT_HELPER,
                        //                 bookingsTotal: {
                        //                     ...DISPLAY_ELEMENT_HELPER.bookingsTotal,
                        //                     short: "Bookings",
                        //                 },
                        //             },
                        //         },
                        //         {
                        //             type: REPORT_CHART_BARS_CATEGORY,
                        //             props: { title: "Top selling rates" },
                        //             groupBy: [GROUP_OFFERS],
                        //             metric: "bookingsTotal",
                        //             metrics: {
                        //                 ...DISPLAY_ELEMENT_HELPER,
                        //                 bookingsTotal: {
                        //                     ...DISPLAY_ELEMENT_HELPER.bookingsTotal,
                        //                     short: "Bookings",
                        //                 },
                        //             },
                        //         },
                        //     ],
                        // },
                    ]}
                />
            </Container>
            <br />
            <br />
            <br />
        </>
    );
};
export default PacingScreen;
