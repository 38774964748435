import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import jsPDF from "jspdf";
import html2canvas from "html2canvas";

import {
    Checkbox,
    CircularProgress,
    Container,
    Drawer,
    FormControlLabel,
    Grid,
    IconButton,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Toolbar,
    Tooltip,
    Typography,
} from "@mui/material";

import TableHeaderCell from "./TableHeaderCell";
import RoundedButton from "./RoundedButton";
import GoBack from "./GoBack";

import Icons from "../helpers/icons";
import Colours from "../helpers/colours";

import { AROLOGO, HOTELS_INFO } from "../configuration";
import ReportBuilder from "./shared/ReportBuilder/ReportBuilder";

const OPENED_SIZE = 300;
const CLOSED_SIZE = 20;
const AUTO_OPEN_ZONE = 10;
const BUTTON_SIZE = 30;

const PDFBuilder = () => {
    const [open, setOpen] = useState(false);
    const [keepOpen, setKeepOpen] = useState(true);

    const components = useSelector((state) => state.PDFExport.data);
    const { id: hotelID } = useSelector((state) => state.hotelID);

    const [data, setData] = useState([]);
    const [reportsOptions, setReportsOptions] = useState({});

    const [newPagePerReport, setNewPagePerReport] = useState(false);
    const [fileName, setFileName] = useState("report.pdf");

    const [isDownloading, setIsDownloading] = useState(false);

    useEffect(() => {
        let auxFileName = `${
            HOTELS_INFO[hotelID].name
        } - ${new Date().toLocaleDateString()}`.replace(/\//g, "_");
        setFileName(auxFileName);
    }, [hotelID]);

    useEffect(() => {
        if (components) {
            let auxReportsOptions = {};
            let auxData = Object.entries(components).map(([key, data]) => {
                auxReportsOptions[key] = {
                    ...(components[key].options ?? {}),
                    ...(reportsOptions[key] ?? {}),
                };
                return [key, data.component];
            });
            setData(auxData);
            setReportsOptions(auxReportsOptions);
            console.log({ auxData, auxReportsOptions, components });
        }
    }, [components]);

    const handleMouseMove = (e) => {
        const x = e.clientX;
        if (x <= AUTO_OPEN_ZONE) {
            setOpen(true);
        }
    };

    const onDragEnd = (result) => {
        if (!result.destination) return;
        const newData = Array.from(data);
        const [relocatedItem] = newData.splice(result.source.index, 1);
        newData.splice(result.destination.index, 0, relocatedItem);
        setData(newData);
    };

    const handleDelete = (index, key) => {
        let auxData = [...data];

        let auxReportsOptions = {
            ...reportsOptions,
        };
        delete auxReportsOptions[key];

        const baseName = key.replace(/\d+$/, "");
        auxData.splice(index, 1);

        // Get all items with the same base name
        const relatedItems = auxData.filter(([name, report]) =>
            name.startsWith(baseName)
        );

        // Rename the remaining items to maintain sequence
        relatedItems.forEach(([name, report], idx) => {
            const newIndex = idx + 1;
            const oldIndex = parseInt(name.match(/\d+$/)[0]);
            if (newIndex !== oldIndex) {
                const newItem = `${baseName}${newIndex}`;
                auxData[
                    auxData.map(([origName, report]) => origName).indexOf(name)
                ] = [newItem, report];
                auxReportsOptions[newItem] = auxReportsOptions[name];
            }
        });

        setData(auxData);

        setReportsOptions(auxReportsOptions);
    };

    const handleDuplicate = (key, index, report) => {
        let auxData = [...data];
        let baseName = key.replace(/\d+$/, "");
        const numbers = auxData
            .filter(([name]) => name.startsWith(baseName))
            .map(([name]) => parseInt(name.match(/\d+$/)))
            .filter((num) => !isNaN(num));
        const newNumber = numbers.length ? Math.max(...numbers) + 1 : 1;
        const copyKey = `${baseName}${newNumber}`;
        auxData.splice(index + 1, 0, [copyKey, report]);
        setData(auxData);
        let auxReportsOptions = { ...reportsOptions };
        auxReportsOptions[copyKey] = { ...reportsOptions[key] };
        setReportsOptions(auxReportsOptions);
    };

    const generatePDF = async () => {
        setIsDownloading(true);
        const element = document.getElementById("content");
        const originalStylesContent = element.style.width;

        element.style.width = "1920px";

        await new Promise((resolve) => setTimeout(resolve, 100)); // Wait 300ms

        const sections = element.querySelectorAll(".page-section");

        const pdf = new jsPDF("p", "mm", "a4");
        const pageHeight = 297;
        const imgWidth = 210;
        let currentPosition = 0;
        let spaceTop = 7;

        const processSection = (section, callback) => {
            html2canvas(section, { scale: 2 }).then((canvas) => {
                const imgData = canvas.toDataURL("image/jpeg");
                const imgHeight = (canvas.height * imgWidth) / canvas.width;

                const endingPosition = currentPosition + spaceTop + imgHeight;

                if (
                    endingPosition > pageHeight ||
                    (newPagePerReport && currentPosition > 0)
                ) {
                    pdf.addPage();
                    currentPosition = 0;
                }
                currentPosition += spaceTop;
                pdf.addImage(
                    imgData,
                    "JPEG",
                    0,
                    currentPosition,
                    imgWidth,
                    imgHeight
                );
                currentPosition += imgHeight;
                callback();
            });
        };

        const processSections = (index) => {
            if (index < sections.length) {
                processSection(sections[index], () =>
                    processSections(index + 1)
                );
            } else {
                element.style.width = originalStylesContent;
                pdf.save(fileName);
                setIsDownloading(false);
            }
        };

        processSections(0);
    };

    return (
        <div>
            {data.length > 0 ? (
                <>
                    <div
                        onMouseMove={handleMouseMove}
                        onMouseLeave={() => {
                            setOpen(false);
                        }}
                        style={{ display: "flex", marginLeft: "1em" }}
                    >
                        <IconButton
                            size="small"
                            style={{
                                width: BUTTON_SIZE,
                                height: BUTTON_SIZE,
                                position: "fixed",
                                top: "100px",
                                right:
                                    open || keepOpen
                                        ? `${OPENED_SIZE - BUTTON_SIZE / 2}px`
                                        : `${CLOSED_SIZE / 2}px`,
                                backgroundColor: Colours.backgroundWhite,
                                zIndex: 1200,
                                transition:
                                    "all 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
                                border: `1px solid ${Colours.navbarBottomLine}`,
                            }}
                            onClick={() => {
                                if (open || keepOpen) {
                                    setKeepOpen(false);
                                    setOpen(false);
                                } else {
                                    setKeepOpen(true);
                                    setOpen(true);
                                }
                            }}
                        >
                            {open || keepOpen ? (
                                <Icons.ArrowRight fontSize="inherit" />
                            ) : (
                                <Icons.ArrowLeft fontSize="inherit" />
                            )}
                        </IconButton>

                        <Drawer
                            variant="permanent"
                            open={open || keepOpen}
                            sx={{
                                position: "absolute",
                                zIndex: 1199,
                                width:
                                    open || keepOpen
                                        ? OPENED_SIZE
                                        : CLOSED_SIZE,
                                transition: (theme) =>
                                    theme.transitions.create("width", {
                                        easing: theme.transitions.easing.sharp,
                                        duration:
                                            theme.transitions.duration
                                                .enteringScreen,
                                    }),
                                "& .MuiDrawer-paper": {
                                    background: "#fafafa",
                                    borderLeft:
                                        open || keepOpen
                                            ? `1px solid ${Colours.navbarBottomLine}`
                                            : "transparent",
                                    justifyContent: "space-start",
                                    overflowX: "hidden",
                                    width:
                                        open || keepOpen
                                            ? OPENED_SIZE
                                            : CLOSED_SIZE,
                                    transition: (theme) =>
                                        theme.transitions.create("width", {
                                            easing: theme.transitions.easing
                                                .sharp,
                                            duration:
                                                theme.transitions.duration
                                                    .leavingScreen,
                                        }),
                                },
                            }}
                            anchor={"right"}
                        >
                            {(open || keepOpen) && (
                                <>
                                    <Toolbar />
                                    <DragDropContext onDragEnd={onDragEnd}>
                                        <Droppable droppableId="droppable">
                                            {(provided) => (
                                                <Table
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                >
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableHeaderCell
                                                                colspan={2}
                                                            >
                                                                Selected Reports
                                                            </TableHeaderCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {data.map(
                                                            (
                                                                [key, report],
                                                                index
                                                            ) => (
                                                                <Draggable
                                                                    key={key}
                                                                    draggableId={
                                                                        key
                                                                    }
                                                                    index={
                                                                        index
                                                                    }
                                                                >
                                                                    {(
                                                                        provided
                                                                    ) => (
                                                                        <TableRow
                                                                            ref={
                                                                                provided.innerRef
                                                                            }
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                        >
                                                                            <TableCell
                                                                                sx={{
                                                                                    borderBottom:
                                                                                        "1px solid lightgrey",

                                                                                    paddingLeft: 2,
                                                                                    paddingY: 1,
                                                                                    paddingRight: 1,
                                                                                }}
                                                                            >
                                                                                <Tooltip title="Visible">
                                                                                    <FormControlLabel
                                                                                        control={
                                                                                            <Checkbox
                                                                                                checked={
                                                                                                    (
                                                                                                        reportsOptions[
                                                                                                            key
                                                                                                        ] ??
                                                                                                        {}
                                                                                                    )
                                                                                                        .visible ??
                                                                                                    false
                                                                                                }
                                                                                                onChange={(
                                                                                                    e
                                                                                                ) => {
                                                                                                    let checked =
                                                                                                        reportsOptions[
                                                                                                            key
                                                                                                        ]
                                                                                                            .visible;
                                                                                                    let auxReportsOptions =
                                                                                                        {
                                                                                                            ...reportsOptions,
                                                                                                        };
                                                                                                    auxReportsOptions[
                                                                                                        key
                                                                                                    ].visible =
                                                                                                        !checked;

                                                                                                    setReportsOptions(
                                                                                                        auxReportsOptions
                                                                                                    );
                                                                                                }}
                                                                                            />
                                                                                        }
                                                                                    />
                                                                                </Tooltip>
                                                                                {
                                                                                    key
                                                                                }
                                                                            </TableCell>
                                                                            <TableCell
                                                                                sx={{
                                                                                    borderBottom:
                                                                                        "1px solid lightgrey",

                                                                                    paddingRight: 2,
                                                                                    paddingLeft: 0,
                                                                                    paddingY: 1,
                                                                                }}
                                                                            >
                                                                                <Tooltip title="Delete">
                                                                                    <IconButton
                                                                                        size="small"
                                                                                        onClick={
                                                                                            () => {
                                                                                                handleDelete(
                                                                                                    index,
                                                                                                    key
                                                                                                );
                                                                                            }
                                                                                            // dispatch(
                                                                                            //     removeOne(
                                                                                            //         key,
                                                                                            //         hotelID
                                                                                            //     )
                                                                                            // )
                                                                                        }
                                                                                    >
                                                                                        <Icons.Delete fontSize="small" />
                                                                                    </IconButton>
                                                                                </Tooltip>

                                                                                {reportsOptions.hasOwnProperty(
                                                                                    key
                                                                                ) &&
                                                                                    reportsOptions[
                                                                                        key
                                                                                    ]
                                                                                        .duplicable && (
                                                                                        <Tooltip title="Duplicate">
                                                                                            <IconButton
                                                                                                size="small"
                                                                                                onClick={() => {
                                                                                                    handleDuplicate(
                                                                                                        key,
                                                                                                        index,
                                                                                                        report
                                                                                                    );

                                                                                                    // dispatch(
                                                                                                    //     loadOne(
                                                                                                    //         report,
                                                                                                    //         copyKey,
                                                                                                    //         hotelID
                                                                                                    //     )
                                                                                                    // );
                                                                                                }}
                                                                                            >
                                                                                                <Icons.Add fontSize="small" />
                                                                                            </IconButton>
                                                                                        </Tooltip>
                                                                                    )}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    )}
                                                                </Draggable>
                                                            )
                                                        )}
                                                        {provided.placeholder}
                                                    </TableBody>
                                                </Table>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                    <br />
                                    <Stack
                                        direction={"column"}
                                        sx={{ padding: 2 }}
                                    >
                                        {/* <label>
                                    Print Scale:
                                    <input
                                        type="number"
                                        value={printScale}
                                        onChange={(e) =>
                                            setPrintScale(
                                                e.target.value
                                            )
                                        }
                                        min="0.1"
                                        step="0.1"
                                    />
                                </label> */}
                                        <TextField
                                            label="Document Title"
                                            variant="filled"
                                            multiline
                                            value={fileName}
                                            onChange={(e) =>
                                                setFileName(e.target.value)
                                            }
                                            required
                                            sx={{ width: "100%" }}
                                        />

                                        <FormControlLabel
                                            sx={{
                                                justifyContent: "center",
                                            }}
                                            control={
                                                <Checkbox
                                                    checked={newPagePerReport}
                                                    onChange={(e) =>
                                                        setNewPagePerReport(
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                            }
                                            label="New page per item"
                                        />
                                        <RoundedButton
                                            id="pdf-download-button"
                                            onClick={generatePDF}
                                            disabled={isDownloading}
                                        >
                                            Download PDF
                                            {isDownloading && (
                                                <CircularProgress />
                                            )}
                                        </RoundedButton>
                                        <GoBack to={"/app/report"} />
                                    </Stack>
                                </>
                            )}
                        </Drawer>
                    </div>

                    <Grid container>
                        <Grid item sx={{ mt: 2, mb: 2 }} xs={12}>
                            <Typography
                                fontStyle={"italic"}
                                variant="h5"
                                justifyContent={"center"}
                                display={"flex"}
                            >
                                Preview your PDF
                            </Typography>
                        </Grid>
                    </Grid>
                    <Paper sx={{ padding: 2 }}>
                        <div id="content">
                            {data.map(([key, report], index) => {
                                return (
                                    <div class="page-section">
                                        <div
                                            style={{
                                                marginTop: 40,
                                                marginLeft: 40,
                                                marginRight: 40,
                                            }}
                                        >
                                            {report}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </Paper>
                </>
            ) : (
                <Typography>
                    We couldn't find any report components to generate a PDF.
                    Please try going back and generating them again.
                </Typography>
            )}
        </div>
    );
};

export default PDFBuilder;
