import React from "react";
import { useSelector } from "react-redux";

import { Typography } from "@mui/material";

import { format } from "date-fns";

const TextPulledAgo = () => {
    const { dataBookings } = useSelector((state) => state);

    const renderTextBasedOnDataType = () => {
        // Showing only last reservation entry
        try {
            return `Data as of ${format(
                new Date(dataBookings.lastEntry),
                "dd MMM yyyy 'at' HH:mm"
            )}`;
        } catch {
            return;
        }
    };

    return (
        <Typography fontSize={"0.8em"} style={{ marginTop: "15px" }}>
            {renderTextBasedOnDataType()}
        </Typography>
    );
};

export default TextPulledAgo;
