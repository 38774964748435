import React from "react";
import { FormControl, MenuItem, Select, Typography } from "@mui/material";

import CheckShow from "./shared/CheckShow";

import { DISPLAY_ELEMENT_HELPER } from "../helpers/filters";
import { ARO_ADMIN } from "../configuration";

const FiltersMetricSelection = ({
    metrics = DISPLAY_ELEMENT_HELPER,
    metric,
    setMetric,
}) => {
    let options = [
        ...[...Object.keys(metrics)].filter((e) => metrics[e].important),
        ...CheckShow({
            rolesThatCanSee: ARO_ADMIN,
            children: [...Object.keys(metrics)].filter(
                (e) => !metrics[e].important
            ),
            alternative: [],
        }),
    ];

    return (
        <FormControl sx={{ mr: 1 }}>
            <Select
                value={metric}
                onChange={(e, v) => {
                    setMetric(v.props.value);
                }}
            >
                {options.map((m) => (
                    <MenuItem key={m} value={m}>
                        <Typography variant="body1">
                            {metrics[m].short ?? m}
                        </Typography>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default FiltersMetricSelection;
