import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
    Button,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    MenuItem,
    Paper,
    Radio,
    RadioGroup,
    Stack,
    TextField,
    Tooltip,
    useMediaQuery,
    useTheme,
    Container,
    Typography,
    Switch,
} from "@mui/material";

import Icons from "../helpers/icons";
import Colours from "../helpers/colours";
import { SETTING_readability } from "../helpers/fabSettings";

import { update } from "../actions/settings";
import { toggleThemeMode } from "../actions/preferences";

import DateSelector from "../components/DateSelector";
import RoundedButton from "../components/RoundedButton";

const SettingsScreen = () => {
    const theme = useTheme();
    let isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const { t, i18n } = useTranslation("navbar");

    const dispatch = useDispatch();
    const {
        user: { jwt, user_id },
    } = useSelector((state) => state.auth);
    const { themeMode = "light" } = useSelector((state) => state.preferences);

    const [clickedSection, setClickedSection] = useState("");

    const [time, setTime] = useState(new Date());
    const [fullYear, setFullYear] = useState(false);
    const [selectedDataSource, setSelectedDataSource] = useState("ResDate");

    const [readability, setReadability] = useState(false);

    const updateTime = (e) => {
        dispatch(update("time", e.toISOString().slice(0, 10), jwt, user_id));
        setTime(e);
    };

    const updateFullYear = (e) => {
        dispatch(update("fullYear", e ? 1 : 0, jwt, user_id));
        setFullYear(e);
    };

    const {
        fullYear: sharedFullYear = false,
        time: sharedTime,
        dataSource: sharedDataSource,
        readability: sharedReadability,
    } = useSelector((state) => state.settings.settings);

    useEffect(() => {
        setReadability(sharedReadability);
    }, [sharedReadability]);

    useEffect(() => {
        console.log({ sharedTime, sharedFullYear });
        if (sharedTime) {
            setTime(new Date(sharedTime));
        }
        // TODO: settings saves fullYear as string ("1" === 1 -> false)
        setFullYear(sharedFullYear === 1 ? true : false);
    }, [sharedTime, sharedFullYear]);

    useEffect(() => {
        setSelectedDataSource(sharedDataSource);
    }, [sharedDataSource]);

    const change = (date, full) => {
        updateTime(date);
        updateFullYear(full);
    };

    const getReportsSettings = () => {
        return (
            <>
                <Stack spacing={2} direction={"column"}>
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="stretch"
                        spacing={4}
                    >
                        <Typography fontWeight={"bold"}>Date</Typography>
                        <DateSelector
                            day={false}
                            time={time}
                            setTime={updateTime}
                            setFullYear={updateFullYear}
                            fullYear={fullYear}
                        />
                    </Stack>
                    <br />
                    <Stack spacing={1}>
                        <RoundedButton
                            disabled={
                                new Date().toISOString().slice(0, 7) ===
                                    time.toISOString().slice(0, 7) &&
                                fullYear === false
                            }
                            onClick={() => change(new Date(), false)}
                        >
                            Current month
                        </RoundedButton>

                        <RoundedButton
                            disabled={
                                new Date(
                                    new Date().getUTCFullYear(),
                                    new Date().getUTCMonth() - 1,
                                    15
                                )
                                    .toISOString()
                                    .slice(0, 7) ===
                                    time.toISOString().slice(0, 7) &&
                                fullYear === false
                            }
                            onClick={() =>
                                change(
                                    new Date(
                                        new Date().getUTCFullYear(),
                                        new Date().getUTCMonth() - 1,
                                        15
                                    ),
                                    false
                                )
                            }
                        >
                            Last month
                        </RoundedButton>

                        <RoundedButton
                            disabled={
                                new Date().toISOString().slice(0, 4) ===
                                    time.toISOString().slice(0, 4) &&
                                fullYear === true
                            }
                            onClick={() => change(new Date(), true)}
                        >
                            {" "}
                            Current year
                        </RoundedButton>

                        <RoundedButton
                            disabled={
                                new Date(new Date().getUTCFullYear() - 1, 6, 15)
                                    .toISOString()
                                    .slice(0, 4) ===
                                    time.toISOString().slice(0, 4) &&
                                fullYear === true
                            }
                            onClick={() =>
                                change(
                                    new Date(
                                        new Date().getUTCFullYear() - 1,
                                        6,
                                        15
                                    ),
                                    true
                                )
                            }
                        >
                            {" "}
                            Last year
                        </RoundedButton>
                    </Stack>
                </Stack>
                <br />
                <Divider
                    sx={{
                        borderColor: Colours.navbarBottomLine,
                    }}
                />
                <br />
                <Stack
                    direction={"column"}
                    display={"flex"}
                    justifyContent={"flex-start"}
                    alignItems={"flex-start"}
                >
                    <Tooltip title="Source data to be used in tables? We will include data that matches: reservation date, check-in date or check-out date, with the current day and previous days.">
                        <Typography fontWeight={"bold"}>Source data</Typography>
                    </Tooltip>
                    <TextField
                        sx={{ pr: 1, pl: 1 }}
                        select
                        defaultValue="CheckOut"
                        value={selectedDataSource}
                        variant="standard"
                        onChange={({ target: { value } }) => {
                            dispatch(update("dataSource", value, jwt, user_id));
                            setSelectedDataSource(value);
                        }}
                    >
                        <MenuItem value={"CheckOut"}>Check-out date</MenuItem>
                        <MenuItem value={"CheckIn"}>Check-in date</MenuItem>
                        <MenuItem value={"ResDate"}>Reservation date</MenuItem>
                    </TextField>
                </Stack>
                <br />
                <Typography
                    fontStyle={"italic"}
                    variant="subtitle2"
                    color="initial"
                    fontSize={"small"}
                >
                    *Although these are general settings, some reports might not
                    use all of them.
                    <br />
                    Look for the individual setting in a specific report to
                    check it's availability.
                </Typography>
            </>
        );
    };

    const getAppearanceSettings = () => {
        return (
            <>
                <Stack spacing={2} direction={"column"}>
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="stretch"
                        spacing={1}
                    >
                        <Typography fontWeight={"bold"}>
                            {t("language")}
                        </Typography>
                        <RadioGroup
                            value={i18n.language}
                            onChange={(event) => {
                                i18n.changeLanguage(event.target.value);
                            }}
                            color="secondary"
                        >
                            <FormControlLabel
                                value="en"
                                control={<Radio color="secondary" />}
                                label="🇬🇧 English"
                            />
                            <FormControlLabel
                                value="es"
                                control={<Radio color="secondary" />}
                                label="🇪🇸 Español"
                            />
                        </RadioGroup>
                    </Stack>
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="stretch"
                        spacing={1}
                    >
                        <Typography fontWeight={"bold"}>
                            {SETTING_readability.text}
                        </Typography>
                        <Stack direction="row">
                            {[Icons[SETTING_readability.icon]].map((I) => (
                                <I key={SETTING_readability.id} />
                            ))}
                            <Switch
                                checked={
                                    String(
                                        readability ??
                                            SETTING_readability.default
                                    ).toLowerCase() === "true"
                                }
                                onChange={(e) => {
                                    dispatch(
                                        update(
                                            "readability",
                                            !(
                                                readability ??
                                                SETTING_readability.default ===
                                                    "true"
                                            ),
                                            jwt,
                                            user_id
                                        )
                                    );
                                }}
                            />
                        </Stack>
                    </Stack>
                </Stack>
            </>
        );
    };

    const getClickedSettings = () => {
        switch (clickedSection) {
            case "reports":
                return getReportsSettings();
                break;
            case "appearance":
                return getAppearanceSettings();
                break;
            default:
                return "";
        }
    };
    return (
        <Container>
            <Grid container>
                <Grid item sx={{ mt: 2, ml: 2, mb: 2 }} xs={12}>
                    <Typography variant="h4">Settings</Typography>
                </Grid>
            </Grid>
        </Container>
    );
    return (
        <>
            <Container>
                <Grid container>
                    <Grid item sx={{ mt: 2, ml: 2, mb: 2 }} xs={12}>
                        <Typography variant="h4">Settings</Typography>
                    </Grid>
                </Grid>{" "}
                <Grid
                    container
                    direction={isMobile ? "row" : "row"}
                    display="flex"
                >
                    <Grid item xs={12} sm={5} md={3}>
                        <Stack>
                            <Container component={Paper}>
                                <br />
                                {/*      ____                       _       
                                        |  _ \ ___ _ __   ___  _ __| |_ ___ 
                                        | |_) / _ \ '_ \ / _ \| '__| __/ __|
                                        |  _ <  __/ |_) | (_) | |  | |_\__ \
                                        |_| \_\___| .__/ \___/|_|   \__|___/
                                                  |_|                        */}
                                <Stack direction={"row"} display={"flex"}>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        sx={{
                                            color: "gray",
                                            boxShadow: "none",
                                            backgroundColor:
                                                clickedSection === "reports"
                                                    ? Colours.primary
                                                    : "inherit",
                                            borderRadius: 50,
                                            textAlign: "center",
                                            ":hover": {
                                                color: "white",
                                                backgroundColor:
                                                    Colours.primary,
                                                boxShadow: "none",
                                            },
                                        }}
                                        onClick={(event) => {
                                            if (clickedSection === "reports")
                                                setClickedSection("");
                                            else setClickedSection("reports");
                                        }}
                                    >
                                        <Typography
                                            display={"flex"}
                                            alignItems={"center"}
                                            variant="h5"
                                            color="initial"
                                            textTransform="capitalize"
                                        >
                                            Reports
                                        </Typography>
                                    </Button>
                                </Stack>
                                <br />
                                {isMobile &&
                                    clickedSection === "reports" &&
                                    getReportsSettings()}
                                <Divider
                                    orientation="horizontal"
                                    flexItem
                                    sx={{
                                        borderColor: Colours.navbarBottomLine,
                                    }}
                                />
                                <br />
                                {/*  _                                                    
                                    / \   _ __  _ __   ___  __ _ _ __ __ _ _ __   ___ ___ 
                                   / _ \ | '_ \| '_ \ / _ \/ _` | '__/ _` | '_ \ / __/ _ \
                                  / ___ \| |_) | |_) |  __/ (_| | | | (_| | | | | (_|  __/
                                 /_/   \_\ .__/| .__/ \___|\__,_|_|  \__,_|_| |_|\___\___|
                                         |_|   |_|                                         */}

                                <Stack direction={"row"} display={"flex"}>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        sx={{
                                            color: "gray",
                                            boxShadow: "none",
                                            backgroundColor:
                                                clickedSection === "appearance"
                                                    ? Colours.primary
                                                    : "inherit",
                                            borderRadius: 50,
                                            textAlign: "center",
                                            ":hover": {
                                                color: "white",
                                                backgroundColor:
                                                    Colours.primary,
                                                boxShadow: "none",
                                            },
                                        }}
                                        onClick={(event) => {
                                            if (clickedSection === "appearance")
                                                setClickedSection("");
                                            else
                                                setClickedSection("appearance");
                                        }}
                                    >
                                        <Typography
                                            display={"flex"}
                                            alignItems={"center"}
                                            variant="h5"
                                            color="initial"
                                            textTransform="capitalize"
                                        >
                                            Appearance
                                        </Typography>
                                    </Button>
                                </Stack>
                                <br />
                                {isMobile &&
                                    clickedSection === "appearance" &&
                                    getClickedSettings()}
                            </Container>
                        </Stack>
                    </Grid>
                    {!isMobile && (
                        <Grid item direction="row" display="flex" sm={7} md={9}>
                            <Divider
                                orientation="vertical"
                                sx={{ marginX: 2, color: "primary" }}
                            />
                            {clickedSection !== "" && (
                                <Stack>
                                    <Container component={Paper} sx={{ p: 4 }}>
                                        {getClickedSettings()}
                                    </Container>
                                </Stack>
                            )}
                        </Grid>
                    )}
                </Grid>
                <br />
                <Typography
                    display={"flex"}
                    justifyContent={"center"}
                    fontStyle={"italic"}
                    variant="subtitle2"
                    color="initial"
                >
                    Missing some important setting? Let us know
                </Typography>
            </Container>
        </>
    );
};

export default SettingsScreen;
