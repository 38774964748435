import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Routes, Route, useLocation } from "react-router-dom";
import { Box, Typography, Grid } from "@mui/material";

import MonthlyReportScreen from "../pages/MonthlyReportScreen";
import MonthlyKPIsScreen from "../pages/MonthlyKPIsScreen";
import MembershipReportScreen from "../pages/MembershipReportScreen";

import SalesSummary from "../components/SalesSummary";
import CheckShow from "../components/shared/CheckShow";
import FiltersReport from "../components/FiltersReport";
import ReportBuilder from "../components/shared/ReportBuilder/ReportBuilder";

import { ARO_ADMIN } from "../configuration";
import { PRESET_MONTH_TO_DATE } from "../helpers/filters";
import { setHotelID } from "../actions/hotelID";

const Dashboard = ({ handleProcessedData, processedData }) => {
    const search = useLocation().search;
    const dispatch = useDispatch();

    const URLpresetDate = new URLSearchParams(search).get("presetDate");
    const URLhotelID = new URLSearchParams(search).get("hotelid");

    useEffect(() => {
        if (URLhotelID) dispatch(setHotelID(URLhotelID));
    }, [URLhotelID]);

    return (
        <>
            <Grid container>
                <Grid item sx={{ mt: 2, mb: 2 }} xs={12}>
                    <Typography variant="h4">Reporting</Typography>
                </Grid>
            </Grid>{" "}
            <FiltersReport
                hide={[
                    "ARRIVAL_PERIOD",
                    "TIME_ARRIVAL_RANGE",
                    "TIME_BOOKING_RANGE",
                    "FILTERS_TYPES",
                ]}
                defaultValues={{
                    presetDate: URLpresetDate ?? PRESET_MONTH_TO_DATE,
                }}
                setProcessed={handleProcessedData}
            />
            <ReportBuilder />
        </>
    );
};

const AroReportScreen = ({}) => {
    const [processedData, setProcessedData] = useState({});

    const handleProcessedData = (data) => {
        setProcessedData(data);
    };

    return (
        <>
            <Routes>
                <Route
                    path=""
                    element={
                        <Dashboard
                            handleProcessedData={handleProcessedData}
                            processedData={processedData}
                        />
                    }
                />
                <Route
                    path="dashboard"
                    element={
                        <Dashboard
                            handleProcessedData={handleProcessedData}
                            processedData={processedData}
                        />
                    }
                />
                <Route
                    path="summary"
                    element={
                        <>
                            <br />
                            <SalesSummary />
                            <br />
                            <CheckShow rolesThatCanSee={ARO_ADMIN}>
                                <MonthlyReportScreen />
                            </CheckShow>
                        </>
                    }
                />
                <Route path="kpis" element={<MonthlyKPIsScreen isolated />} />
                <Route
                    path="membership"
                    element={
                        <>
                            <br />
                            <MembershipReportScreen />
                        </>
                    }
                />
            </Routes>
        </>
    );
};
export default AroReportScreen;
