import React from "react";

import DataLoader from "../components/DataLoader";

import ReportBuilder, {
    ACTIVE_FILTERS_LINE,
    REPORT_CHART_HEATMAP_CALENDAR,
    REPORT_CHART_BARS,
    REPORT_CHART_BARS_CATEGORY,
    REPORT_CHART_LINES,
    REPORT_CHART_IN_TABLE,
    REPORT_TABLE_METRICS_SUMMARY,
    REPORT_TABLE_OVERVIEW,
    REPORT_TABLE_OVERVIEW_YEAR_MONTH,
    REPORT_TABLE_RESERVATIONS,
    REPORT_CHART_WORLD_MAP,
    REPORT_CHART_TREND_DAILY_WEEKLY_MONTHLY,
} from "../components/shared/ReportBuilder/ReportBuilder";

import {
    DATATYPE_CHECKIN,
    DATATYPE_RESDATE,
    DISPLAY_ELEMENT_HELPER,
    GROUP_COUNTRIES,
    GROUP_DATE_DAY,
    GROUP_DATE_MONTH,
    GROUP_DATE_WEEK,
    GROUP_DATE_YEAR,
    GROUP_OFFERS,
    GROUP_ROOM,
} from "../helpers/filters";

const TestScreen = () => {
    return (
        <>
            This is a developer's page
            <br />
            <DataLoader Reservations />
            <ReportBuilder
                reports={[
                    {
                        type: ACTIVE_FILTERS_LINE,
                        filters: { Types: ["Voucher"] },
                    },
                    {
                        type: REPORT_CHART_BARS_CATEGORY,
                        props: { title: REPORT_CHART_BARS_CATEGORY },
                        groupBy: [GROUP_ROOM],
                        metric: "bookingsTotal",
                        metrics: {
                            ...DISPLAY_ELEMENT_HELPER,
                            bookingsTotal: {
                                ...DISPLAY_ELEMENT_HELPER.bookingsTotal,
                                short: "Bookings",
                            },
                        },
                    },
                    {
                        type: REPORT_CHART_LINES,
                        groupBy: [
                            GROUP_COUNTRIES,
                            GROUP_DATE_YEAR,
                            GROUP_DATE_MONTH,
                            GROUP_DATE_DAY,
                        ],
                        props: { title: REPORT_CHART_LINES },
                    },
                    {
                        type: REPORT_CHART_BARS,
                        groupBy: [
                            GROUP_COUNTRIES,
                            GROUP_DATE_YEAR,
                            GROUP_DATE_MONTH,
                            GROUP_DATE_DAY,
                        ],
                        props: { title: REPORT_CHART_BARS },
                    },
                    {
                        type: REPORT_CHART_HEATMAP_CALENDAR,
                        props: { title: REPORT_CHART_HEATMAP_CALENDAR },
                        groupBy: [],
                    },
                    {
                        type: REPORT_CHART_IN_TABLE,
                        dataType: DATATYPE_RESDATE,
                        groupBy: [GROUP_DATE_WEEK],
                        props: { title: REPORT_CHART_IN_TABLE + " + weekly" },
                    },
                    {
                        type: REPORT_CHART_IN_TABLE,
                        dataType: DATATYPE_RESDATE,
                        groupBy: [GROUP_DATE_YEAR, GROUP_DATE_MONTH],
                        props: {
                            title: REPORT_CHART_IN_TABLE + " + year month",
                        },
                    },
                    {
                        type: REPORT_TABLE_METRICS_SUMMARY,
                        props: {
                            title: REPORT_TABLE_METRICS_SUMMARY,
                        },
                        groupBy: [],
                        dataType: DATATYPE_RESDATE,
                        fieldsOrder: [
                            "revenueRoom",
                            "bookingsNotCancelled",
                            "roomNightsTotal",
                            "ADRTotal",
                        ],
                        hideFields: [
                            ...Object.keys(DISPLAY_ELEMENT_HELPER).filter(
                                (e) =>
                                    ![
                                        "bookingsNotCancelled",
                                        "roomNightsTotal",
                                        "revenueRoom",
                                        "ADRTotal",
                                    ].includes(e)
                            ),
                        ],
                        PDF: "Metrics",
                        // props: { hideEvents: true },
                    },
                    {
                        type: REPORT_TABLE_OVERVIEW,
                        props: {
                            title: REPORT_TABLE_OVERVIEW,
                        },
                        groupBy: [GROUP_COUNTRIES],
                        fieldsOrder: ["bookingsRooms", "roomNightsTotal"],
                        hideFields: [
                            ...Object.keys(DISPLAY_ELEMENT_HELPER).filter(
                                (e) =>
                                    ![
                                        "bookingsRooms",
                                        "roomNightsTotal",
                                    ].includes(e)
                            ),
                        ],
                    },
                    {
                        type: REPORT_TABLE_OVERVIEW_YEAR_MONTH,
                        props: {
                            title: REPORT_TABLE_OVERVIEW_YEAR_MONTH,
                        },
                        dataType: DATATYPE_CHECKIN,
                        groupBy: [GROUP_DATE_YEAR, GROUP_DATE_MONTH],
                        sortable: [],
                        filters: { Types: ["Special Offer"] },
                        fieldsOrder: [
                            "bookingsRooms",
                            "roomNightsTotal",
                            "revenueRoom",
                        ],
                        hideFields: [
                            ...Object.keys(DISPLAY_ELEMENT_HELPER).filter(
                                (e) =>
                                    ![
                                        "bookingsRooms",
                                        "roomNightsTotal",
                                        "revenueRoom",
                                    ].includes(e)
                            ),
                        ],
                    },
                    {
                        type: REPORT_TABLE_RESERVATIONS,
                        props: {
                            title: REPORT_TABLE_RESERVATIONS,
                        },
                    },
                    {
                        type: REPORT_CHART_WORLD_MAP,
                        props: {
                            title: REPORT_CHART_WORLD_MAP,
                        },
                        groupBy: [GROUP_COUNTRIES],
                    },
                    {
                        type: REPORT_CHART_TREND_DAILY_WEEKLY_MONTHLY,
                        groupBy: [
                            GROUP_OFFERS,
                            GROUP_DATE_YEAR,
                            GROUP_DATE_MONTH,
                            GROUP_DATE_DAY,
                        ],
                        filters: { Types: ["Special Offer"] },
                        props: {
                            title: REPORT_CHART_TREND_DAILY_WEEKLY_MONTHLY,
                            metricsTitle: "‎ ",
                            // metrics: {
                            //     bookingsTotal: {
                            //         ...DISPLAY_ELEMENT_HELPER.bookingsTotal,
                            //         short: "Arrivals",
                            //     },
                            //     roomNightsTotal: {
                            //         ...DISPLAY_ELEMENT_HELPER.roomNightsTotal,
                            //     },
                            //     revenueRoom: {
                            //         ...DISPLAY_ELEMENT_HELPER.revenueRoom,
                            //     },
                            //     ADRTotal: {
                            //         ...DISPLAY_ELEMENT_HELPER.ADRTotal,
                            //     },
                            // },
                            // showLastYear: true,
                            showLastYear: false, //TODO: this is not working
                            showLegend: false,
                        },
                    },
                    {
                        type: REPORT_CHART_TREND_DAILY_WEEKLY_MONTHLY,
                        groupBy: [
                            GROUP_OFFERS,
                            GROUP_DATE_YEAR,
                            GROUP_DATE_MONTH,
                            GROUP_DATE_DAY,
                        ],
                        filters: { Types: ["Special Offer"] },
                        props: {
                            title:
                                REPORT_CHART_TREND_DAILY_WEEKLY_MONTHLY +
                                "\twith last year and legend",
                            metricsTitle: "‎ ",
                            showLastYear: true, //TODO: this is not working
                            showLegend: true,
                        },
                    },
                ]}
            />
        </>
    );
};
export default TestScreen;
