import React, { useEffect, useState } from "react";

import {
    Container,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Table,
    TableBody,
    Typography,
} from "@mui/material";

import EChartsReact from "echarts-for-react";
import {
    DISPLAY_ELEMENT_HELPER,
    GB_NotDate,
    GROUP_COUNTRIES,
    GROUP_OFFERS,
    GROUP_ROOM,
    GROUP_TYPE,
    formatDate,
} from "../../../helpers/filters";
import Colours from "../../../helpers/colours";
import HoverPaper from "../HoverPaper";
import { tooltip } from "../../../helpers/eCharts";
import NoDataRow from "../../NoDataRow";
import { useSelector } from "react-redux";
import FiltersMetricSelection from "../../FiltersMetricSelection";
import { HOTELS_INFO } from "../../../configuration";
import { fns } from "../../../helpers/common";

export const ReportChartBarOrLines = ({
    data,
    chartType = "bar",
    entriesLimit = 7,
    entriesLimitSort = "descending", // or "ascending"
    initialMetric = "bookingsTotal",
    title,
}) => {
    const js = useSelector((state) => state.globalReportFilters);
    const { id: hotelID } = useSelector((state) => state.hotelID);

    const [formattedData, setFormattedData] = useState({});
    const [update, setUpdate] = useState(0);
    const [metric, setMetric] = useState(initialMetric);
    const [total, setTotal] = useState(0);
    // (revenueTotal,roomNightsTotal)
    useEffect(() => {
        if (Object.values(data).length > 0 && GB_NotDate(js).length >= 1) {
            let aux_total = 0;
            const calculateTop = (useLastYear = false) => {
                let groupedBy = {};
                let sums = {};
                Object.values(data).forEach(
                    ({ keys, result, resultOneYearBefore }) => {
                        let aux_r = useLastYear ? resultOneYearBefore : result;
                        const { [metric]: metric_ } = aux_r ?? {};
                        if (metric_) {
                            aux_total += useLastYear ? 0 : metric_;
                            const {
                                Month = 1,
                                Year = new Date().getUTCFullYear(),
                                Day = 1,
                                [GROUP_COUNTRIES]: Country = "",
                                [GROUP_OFFERS]: Offers = "",
                                [GROUP_ROOM]: Rooms = "",
                                [GROUP_TYPE]: Type = "",
                            } = Object.fromEntries(keys);

                            const key = [Country, Offers, Rooms, Type]
                                .filter((e) => (e ?? "").length > 0)
                                .join("/");

                            if (!groupedBy[key]) {
                                groupedBy[key] = [];
                                sums[key] = 0;
                            }

                            groupedBy[key].push([
                                formatDate(new Date(Year, Month - 1, Day)),
                                metric_,
                            ]);

                            sums[key] = sums[key] + metric_;
                        } else {
                        }
                    }
                );
                sums = Object.entries(sums).sort((a, b) =>
                    entriesLimitSort === "ascending" ? a[1] - b[1] : b[1] - a[1]
                );
                let top = sums.slice(0, entriesLimit);

                let topN = {};
                top.map(([k, v]) => {
                    topN[k] = groupedBy[k]; // Copy a Top key to tops
                    delete groupedBy[k]; // Delete that key from the source
                });

                // Calculate Others
                let Others = {};
                if (Object.keys(groupedBy).length > 0) {
                    Object.values(groupedBy).map((arr) => {
                        arr.map((subArr) => {
                            if (!Others[subArr[0]]) Others[subArr[0]] = 0;
                            Others[subArr[0]] = Others[subArr[0]] + subArr[1];
                        });
                    });
                    topN["Others"] = Object.entries(Others).sort((a, b) =>
                        entriesLimitSort === "ascending"
                            ? a[0].localeCompare(b[0])
                            : b[0].localeCompare(a[0])
                    );
                }
                return topN;
            };

            const topN = calculateTop();

            const isSingle = Object.keys(topN).length === 1;
            let lastYear = [];
            if (isSingle) {
                // If there is only one entry, we show last year performance
                lastYear = calculateTop(true);
            }

            const makeSerie = (name, data, colour = null) => {
                const serie = {
                    type: chartType,
                    name: name,
                    data: data.sort((a, b) =>
                        entriesLimitSort === "ascending"
                            ? a[0].localeCompare(b[0])
                            : b[0].localeCompare(a[0])
                    ),
                    smooth: true,
                    symbol: "none",
                };
                if (colour) {
                    serie.itemStyle = { color: colour };
                    serie.lineStyle = { color: colour };
                    serie.symbolStyle = { color: colour };
                }
                return serie;
            };

            const series = [
                ...Object.entries(lastYear).map(([name, data]) =>
                    makeSerie(
                        name + " one year before",
                        data,
                        isSingle ? Colours.secondarySoft : null
                    )
                ),
                ...Object.entries(topN).map(([name, data]) =>
                    makeSerie(name, data, isSingle ? Colours.secondary : null)
                ),
            ];

            setFormattedData({
                series,
                xAxisData: Object.values(topN)[0]
                    .map(([date]) => date)
                    .sort(),
            });
            setTotal(aux_total);
            setUpdate(update + 1);
        }
    }, [data, metric]); //, js
    if (GB_NotDate(js).length >= 1)
        return (
            <>
                {title && (
                    <Typography fontWeight={"bold"} sx={{ pt: 2 }}>
                        {title}
                    </Typography>
                )}
                <Container
                    maxWidth={false}
                    component={HoverPaper}
                    sx={{ p: 2 }}
                >
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                    >
                        <FiltersMetricSelection
                            metric={metric}
                            setMetric={setMetric}
                        />
                        <Typography fontWeight={"bold"}>
                            Total:{" "}
                            {fns(
                                hotelID,
                                total,
                                DISPLAY_ELEMENT_HELPER[metric].format ?? {}
                            )}
                        </Typography>
                    </Stack>

                    {Object.values(data).length > 0 ? (
                        <EChartsReact
                            key={update}
                            option={{
                                tooltip: tooltip,
                                legend: {},
                                xAxis: {
                                    type: "category",
                                    data: formattedData.xAxisData,
                                },
                                yAxis: {},

                                series: formattedData.series,
                            }}
                        />
                    ) : (
                        <Table>
                            <TableBody>
                                <NoDataRow text="No data for these filters" />
                            </TableBody>
                        </Table>
                    )}
                </Container>
            </>
        );
    return null;
};

export default ReportChartBarOrLines;
